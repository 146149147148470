<template>
  <el-main>
    <div class="scanCodeGun-img">
      <div class="title">pad推荐：华为pad（只支持华为pad）</div>
      <ul>
        <li @click="selectScanCodeGun(index)" v-for="(item, index) in img_list" :key="index">
          <div class="img" :style="{ 'background-image': 'url(' + item.img + ')', 'background-size': '100% 100%' }"></div>
          <el-image v-show="item.is_select" :src="require('@/assets/image/hardware/gou@2x.png')"></el-image>
          <p>{{ item.name }}</p>
        </li>
      </ul>
      <el-button @click="go" type="primary">官网购买</el-button>
    </div>
    <div class="connect-methods">
      <div class="title">打开彩蛋数字收银台方式</div>
      <div class="content">
        <p>1.首先打开华为“应用市场”;</p>
        <p>2.打开“应用市场后”点击“快应用”选项:</p>
        <p>3.点击完快应用后，需要“同意”服务条款，然后需要等待加载。</p>
        <p>4.搜索彩蛋数字收银台，直接点击使用，无需安装。</p>
        <p>5.最近使用的快应用可以在左滑负一屏找到。</p>
        <p>6.可以把彩蛋数字收银台添加到桌面，方便下次使用。</p>
      </div>
    </div>
  </el-main>
</template>
<script>
import img1 from '@/assets/image/hardware/pic_1@2x.png'
import img2 from '@/assets/image/hardware/pic_2@2x.png'
import img3 from '@/assets/image/hardware/pic_3@2x.png'
import img4 from '@/assets/image/hardware/pic_4@2x.png'
import img5 from '@/assets/image/hardware/pic_5@2x.png'
export default {
  data() {
    return {
      img_list: [
        {
          name: '华为平板m6',
          path: 'https://huawei.jd.com/',
          img: img1,
          is_select: !0
        },
        {
          name: '华为Matepad Pro',
          path: 'https://item.jd.com/100022491836.html',
          img: img2,
          is_select: !1
        },
        {
          name: '华为Matepad 11',
          path: 'https://item.jd.com/100023428858.html',
          img: img3,
          is_select: !1
        },
        {
          name: '华为畅享平板2',
          path: 'https://item.jd.com/100007932571.html',
          img: img4,
          is_select: !1
        },
        {
          name: '华为Matepad 10.8',
          path: 'https://item.jd.com/100022491870.html',
          img: img5,
          is_select: !1
        }
      ]
    }
  },
  methods: {
    selectScanCodeGun(index) {
      this.img_list.map((item) => (item.is_select = !1))
      this.img_list[index].is_select = !this.img_list[index].is_select
    },
    go() {
      let img_list = this.img_list.filter((item) => item.is_select)
      window.open(img_list[0].path, '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
.el-main {
  background: #fff;
  font-size: 14px;
  .scanCodeGun-img {
    .title {
      padding-left: 10px;
      line-height: 36px;

      margin-bottom: 15px;
      background: #f7f8fa;
    }
    ul {
      display: flex;
      margin-bottom: 40px;
      li {
        margin-right: 15px;
        width: 148px;
        text-align: center;
        position: relative;
        .el-image {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 10;
          width: 29px;
          height: 29px;
        }
        .img {
          width: 100%;
          height: 148px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .connect-methods {
    margin-top: 48px;
    .title {
      padding-left: 10px;
      line-height: 36px;
      margin-bottom: 15px;
      background: #f7f8fa;
    }
    .content {
      margin-top: 14px;
      p {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
